<template>
  <div class="bulk--repost-listings">
    <div v-for="(listing, index) in selectedListings" :key="`bulk-repost-listing-${index}`">
      <div v-if="listing" class="card--my-listing">
        <div class="left--section">
          <a target="_blank" style="text-decoration: none" @click="openDetailLink">
            <div v-if="listing.primary_photo">
              <picture>
                <source :srcset="listing.primary_photo" type="image/webp" />
                <source :srcset="getFallbackImage(listing.primary_photo)" type="image/jpeg" />
                <img :src="getFallbackImage(listing.primary_photo)" />
              </picture>
            </div>
          </a>
        </div>
        <div class="right--section">
          <div class="top--my-listing">
            <div class="left--col">
              <div class="title--text" :class="{ 'multiline-ellipsis-2': $mq === 'xs' }">
                {{ listing.title }}
              </div>
              <div class="label--container">
                <div class="label">{{ listing.property_type }}</div>
              </div>
              <div
                class="listing--number"
                :id="`copyToClipboardListingNumber-${index}-${listing.listing_number}`"
                @click="copyToClipboard(listing.listing_number)"
                v-if="listing.listing_number"
              >
                <div>
                  {{ `${$t('general.listingNumber')}: ${listing.listing_number}` }}
                </div>
                <div>
                  <v-icon>mdi-content-copy</v-icon>
                </div>
              </div>
              <b-popover
                :show="copyListingNumberSucceededListingNumber === listing.listing_number"
                :target="`copyToClipboardListingNumber-${index}-${listing.listing_number}`"
                title=""
              >
                {{ $t('general.success') }} <strong> {{ $t('general.clipboard') }}</strong>
              </b-popover>
              <div class="price">
                {{ $n(listing.price_num, 'currency', 'id-ID') }}
              </div>
            </div>
            <div class="right--col">
              <v-icon @click="removeListing(index)" class="trash--button">mdi-close</v-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapState } from 'vuex';

export default {
  name: 'bulk-repost-listings',
  mixins: [HelperMixin, LodashMixin],
  components: {},
  props: [],
  data: () => ({
    copyListingNumberSucceededListingNumber: null,
  }),
  computed: {
    ...mapState({
      selectedListings: (state) => state.listing.selectedListings,
    }),
    userWebsiteUrl() {
      return process.env.VUE_APP_USER_URL;
    },
  },
  mounted() {},
  methods: {
    removeListing(index) {
      this.$store.commit('listing/REMOVE_SELECTED_LISTINGS', index);
    },
    openDetailLink(listing) {
      if (listing.links && listing.links.detail) {
        window.open(`${this.userWebsiteUrl}${listing.links.detail}`, '_blank');
      }
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.handleCopyListingNumberStatus(value);
    },
    async handleCopyListingNumberStatus(listingNumber) {
      this.copyListingNumberSucceededListingNumber = listingNumber;
      let self = this;
      setTimeout(() => {
        self.copyListingNumberSucceededListingNumber = null;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.bulk--repost-listings {
  .card--my-listing {
    margin-bottom: 16px;
    .left--section {
      img {
        height: 180px;
      }
    }
    .right--section {
      .top--my-listing {
        align-items: center;
        .right--col {
          display: flex;
          align-items: center;
          justify-content: right;
          .trash--button {
            font-size: 60px;
            color: $color-beliruma-1;
            @media #{$phones} {
              font-size: 40px;
            }
          }
        }
      }
    }
  }
}
</style>
